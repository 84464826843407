@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

* {
  outline: 0 !important;
}

img, svg {
  -moz-user-select: none; 
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
  pointer-events: none;
}

body {
  color: #1AF012;
  background-color: #080808;
  top: 0 !important;
}

.font-jerbrains {
    font-family: "JetBrains Mono", monospace;
}

.Toastify__toast-container {
  z-index: 2147483610 !important;
}

.Toastify__toast-icon {
  order: 1;
}


.swiper-slide {
  width: 60%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-slide:nth-child(n+1) {
  width: 40%;
}

.swiper-slide-next {
  opacity: 0.2;
}

.swiper-slide-prev {
  opacity: 0.2;
}

.swiper-slide.swiper-slide-active {
  z-index: 3 !important;
}

.swiper {
  position: relative;
  &:before {
    z-index: 2;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 100%;
    background-image: url('../public/icons/main/levelsMap/leftShadow.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 767px) {
      width: 100px;
      left: -5px;
    }
  }

  &:after {
    z-index: 2;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 100%;
    background-image: url('../public/icons/main/levelsMap/rightShadow.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 767px) {
      width: 100px;
      right: -5px;
    }
  }
}

.header-dialog-overlay {
  position: 'fixed';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 88;
  overflow: 'hidden';
  display: 'flex';
  align-items: center;
  justify-content: center;
  background-color: rgba(11, 11, 11, 0.70);
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.font-overlock {
  font-family: 'Overlock', sans-serif;
}

.cyrcle_shadow_inset {
  box-shadow: 0px 0px 20px 0px #00000073;
  box-shadow: 0px 0px 5px 0px #BDE7FF59 inset;
}

.cyrcle_shadow {
  box-shadow: 0px 0px 20px 0px #00000073;
  box-shadow: 0px 0px 5px 0px #BDE7FF59;
}

.button_shadow {
  box-shadow: 0px 0px 10px 0px #00000026;
  box-shadow: 0px 0px 4px 0px #FFFFFF26 inset;
}

.green_button_shadow {
  box-shadow: 0px 0px 40px 0px #00FF38A6;
}

.main_block_shadow {
  box-shadow: 0px 0px 5px 0px #BDE7FF59 inset;

}

.arrow_button_shadow {
  box-shadow: 0px 0px 4px 0px #FFFFFF26 inset;
}

.timer_bg {
  background: linear-gradient(180deg, #141414 0%, #202020 100%), linear-gradient(180deg, rgba(63, 63, 63, 0) 0%, rgba(106, 106, 106, 0.4) 100%);
}

.timer_text_color {
  background: linear-gradient(155deg, #B2BCBC 9.18%, rgba(62, 189, 198, 0.00) 130.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.levels_bg_even {
  background: linear-gradient(0deg, #21252C, #21252C), linear-gradient(243.43deg, #484D56 5.19%, rgba(7, 10, 16, 0) 97.19%);
}

.levels_bg_uneven {
  background: linear-gradient(0deg, #464C5B, #464C5B), linear-gradient(245.32deg, #777E8E 3.62%, rgba(35, 35, 35, 0) 95.75%);

}

.levels_green_shadow {
  box-shadow: 0px 0px 25px 0px #48FF44BF inset, 0px 0px 10px 0px #FFFFFF inset;
}

.levels_red_shadow {
  box-shadow: 0px 0px 10px 0px #0000008C, 0px 0px 25px 0px #FF4444BF inset, 0px 0px 10px 0px #FF4444 inset;

}

.levels_white_shadow {
  box-shadow: 0px 0px 10px 0px #FFFFFF inset;
}

.fade-text {
  mask-image: linear-gradient(to bottom, transparent, black 95%, black 0%, transparent);
}

.buttonEllipse {
  border-radius: 100%;
}

.buttonBody {
  background: radial-gradient(88.92% 100% at 49.83% 0%, #1AF012 0%, #002B00 100%);
}

.mainButton:active {
  .buttonBody {
    border-radius: 90px 90px 80% 80%;
  }
  .buttonEllipse  {
    transform: translateY(60px) translateX(-50%);
    @media (max-width: 767px) {
      transform: translateY(30px) translateX(-50%);
    }
  }
}

.mainButton.pressed {
  .buttonBody {
    border-radius: 90px 90px 80% 80%;
  }
  .buttonEllipse  {
    transform: translateY(60px) translateX(-50%);
    @media (max-width: 1413px) {
      transform: translateY(40px) translateX(-50%);
    }
    @media (max-width: 1233px) {
      transform: translateY(40px) translateX(-50%);
    }
    @media (max-width: 1060px) {
      transform: translateY(30px) translateX(-50%);
    }
  }
}

.iconsShadow {
  filter: drop-shadow(0px 0px 44.609px rgba(26, 240, 18, 0.20));
}

.buttonMainStyle {
  background-color: #1AF012;
}

.buttonMainStyle:hover {
  background-color: #56FF4E;
  box-shadow: 0px 0px 30px 0px rgba(26, 240, 18, 0.45);
}

.buttonSecondStyle {
  background-color: #1AF012;
}

.buttonSecondStyle:hover {
  background-color: #56FF4E;
  box-shadow: 0px 0px 40px 0px rgba(26, 240, 18, 0.25);
}

.modalCloseIconShadow {
  color: #1AF012;
  line-height: 0;
}
.modalCloseIconShadow:hover {
  box-shadow: 0px 0px 40px 0px rgba(26, 240, 18, 0.25);
}

.copyIcon {
  background-image: url('../public/icons/modals/inviteModal/greenCopyIcon.svg');

}
.copyIcon:active {
  background-image: url('../public/icons/modals/inviteModal/greenActiveCopyIcon.svg');
}

.infoIcon {
   background-image: url('../public/icons/main/leftBar/infoIcon.svg');
    @media (max-width: 767px) {
      width: 14.33px;
      height: 14.33px;
    }
}

// .infoIcon:hover {
//   background-image: url('../public/icons/main/leftBar/infoIconHover.svg');
// }


.loading {
  display:inline-block;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}
@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.animation1 {
  height: 350px;
  width: 90%;
  -webkit-animation-name: bg;
  -webkit-animation-duration: 2s;
  animation-name: bg;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background-position: center top;
}

@keyframes bg {
  0% {
    background-image: url('../public/danceAnimation1.png');
    background-repeat: no-repeat;
  }
  33% {
    background-image: url('../public/danceAnimation2.png');
    background-repeat: no-repeat;
  }
  66% {
    background-image: url('../public/danceAnimation3.png');
    background-repeat: no-repeat;
  }
  100% {
    background-image: url('../public/danceAnimation4.png');
    background-repeat: no-repeat;
  }
}

.levelsItem-hover:hover {
  background: linear-gradient(0deg, rgba(26, 240, 18, 0.10) 0%, rgba(26, 240, 18, 0.10) 100%), #0A0A0B;
}

.levelsItem-choosed {
  background: linear-gradient(0deg, rgba(26, 240, 18, 0.10) 0%, rgba(26, 240, 18, 0.10) 100%), #0A0A0B;
}

.levelsItem-shadow {
  box-shadow: 0px 0px 26.931px 0px rgba(26, 240, 18, 0.20), 0px 0px 20.198px 0px rgba(26, 240, 18, 0.50) inset;
}